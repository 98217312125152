<template>
  <div class="container">
    <div class="content">
      <!-- 回收站操作 -->
      <div class="recycle-actions">
        <div class="recycle-actions-content" v-if="checkLength2==0">
          <div class="btn" @click="showDelete1 = true">
            <img src="@assets/img/profile/icon-delete2.png"/>
            <span>清空回收站</span>
          </div>
          <p>超过7天的文件无法恢复</p>
        </div>
        <div class="recycle-actions-content" v-else>
          <div class="actions">
            <div class="action-item" @click="handelreductionfile">
              <img src="@assets/img/profile/icon-restore.png"/>
              <span>还原</span>
            </div>
            <div class="action-item" @click="showCompleteDelete=true;">
              <img src="@assets/img/profile/icon-delete4.png"/>
              <span>彻底删除（粉碎）</span>
            </div>
          </div>
          <p>已选{{checkLength2}}项</p>
        </div>
      </div>
      <!-- 回收站 -->
      <div class="recycle-contain" >
        <div class="pan-contain-nav" v-if="recycleList.length!=0">
          <div class="pan-contain-nav-left">
            <div class="titleBox">
              <img src="@assets/img/index/icon-files.png"/>
              <span>全部文件</span>
            </div>
<!--            <img src="@assets/img/profile/icon-sort.png" style="cursor:pointer;"/>-->
          </div>
        </div>
        <!-- 回收站列表 -->
        <div class="recycle-contain-body" v-if="recycleList.length!=0">
          <div class="pan-table__header">
            <table>
              <colgroup>
                <col width="5%">
                <col width="40%">
                <col width="40%">
                <col width="15%">
              </colgroup>
              <thead>
              <tr>
                <th class="pan-table__header-select">
                  <img :src="isCheckAll2?selected:unselected" @click="handleClickAll2"/>
                </th>
                <th style="text-align:left;">文件名</th>
                <th>删除时间</th>
                <th>文件大小</th>
              </tr>
              </thead>
            </table>
          </div>
          <div class="pan-table__body" @scroll="scrollEvent($event,2)">
            <table>
              <colgroup>
                <col width="5%">
                <col width="40%">
                <col width="40%">
                <col width="15%">
              </colgroup>
              <tbody>
              <tr v-for="(item,index) in recycleList" :key="index" :class="item.checked?'selected':''">
                <td class="pan-table__body-checkbox">
                  <img :src="item.checked?selected:unselected" @click="item.checked=!item.checked"
                       :style="item.checked?'visibility: visible;':''"/>
                </td>
                <td class="pan-list__file-name">
                  <img :src="getImgType(item.suffix)"/>
                  <router-link to="">{{item.name}}</router-link>
                  <!--                    <i>12文件</i>-->
                </td>
                <td class="pan-list__time">
                  {{item.delete_time}}
                </td>
                <td class="pan-list__size">
                  <!--                    29.55KB-->
                  <span>{{item.old_size?formatSize(item.old_size):'-'}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="checked-size" v-if="checkLength2!=0">共{{total}}项</div>
        <div class="recycle-contain-empty" v-if="recycleList.length==0">
          <img src="@assets/img/profile/icon-empty.png"/>
          <p>回收站是空的</p>
        </div>
      </div>
    </div>

    <!--  清空回收站-->
    <div class="overlay" v-show="showDelete1">
      <div class="wrap">
        <div class="delete-dialog">
          <img src="@assets/img/index/icon-close2.png" class="close"/>
          <div class="dialog-body">
            <p>确定要清空回收站吗？</p>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handelemptyrecycle">确定</div>
            <div class="btn cancel-btn" @click="showDelete1=false">
              <span>取消</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 彻底删除弹窗 -->
    <div class="overlay" v-show="showCompleteDelete">
      <div class="wrap">
        <div class="complete_delete-dialog">
          <div class="dialog-header">
            <span>彻底删除(粉碎)</span>
          </div>
          <div class="dialog-body">
            <p>确定要彻底删除(粉碎)已选的{{checkLength2}}项吗？注意：彻底粉碎的文件无法再恢复</p>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handelsmashfile">确定</div>
            <div class="btn cancel-btn" @click="showCompleteDelete=false">取消</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {emptyRecycle, getRecycleList, reductionRecycle, smashRecycleFile} from "@assets/api/myFiles";
import {getUserInfo} from "@assets/api/user/user";

export default {
  name: "Recycle",
  data(){
    return{
      selected:require('../../../assets/img/profile/icon-selected.png'),
      unselected:require('../../../assets/img/profile/icon-unselected.png'),
      unselected2:require('../../../assets/img/profile/icon-unselected2.png'),
      showDelete:false, //删除弹窗显示
      isCheckedDelete:false,//删除文件是否永久销毁
      showDelete1:false,//清空回收站
      //回收站
      showCompleteDelete:false, //彻底删除弹窗

      //下标
      pageIndex:1,
      recycleList:[],//回收站数组
      total:0,//总数
    }
  },
  computed:{
    //回收站 选中的文件数量
    checkLength2(){
      return this.recycleList.filter(item=>{
        return item.checked
      }).length
    },
    //回收站 判断全选
    isCheckAll2(){
      if(!this.recycleList.length){
        return false
      }
      return this.recycleList.length===this.checkLength2
    },
  },
  created(){
    this.getRecycleListData()
  },
  methods:{
    //用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          let userInfo = res.data
          // let time = userInfo.vip_expiry_time.split(' ')
          // userInfo.vip_expiry_time = time[0]
          this.$store.commit('getUserInfo',userInfo)
        }
      })
    },
    //获取回收站数据
    getRecycleListData(){
      let params ={
        page:this.pageIndex,
        limit:50
      }
      getRecycleList(params).then(res=>{
        let arr = []
        arr = res.data.list
        this.total = res.data.count
        for(let item in arr){
          this.$set(arr[item],'checked',false)
          if(arr[item].type == 1){
            this.$set(arr[item],'suffix','folder')
          }
          this.recycleList.push(arr[item])
        }
      })
    },
    //还原文件
    handelreductionfile(){
      let files_list = []
      for(let item in this.recycleList){
        if(this.recycleList[item].checked == true){
          files_list.push({
            id:this.recycleList[item].id
          })
        }
      }
      let data = {
        files_list: files_list
      }
      console.log('还原data',data.files_list)
      reductionRecycle(data).then( async res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          await this.getRecycleListData()
          await this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //点击全选 回收站
    handleClickAll2(){
      if(this.isCheckAll2){
        this.recycleList.forEach(item=>item.checked=false)
      }else{
        this.recycleList.forEach(item=>item.checked=true)
      }
    },
    //滚动
    scrollEvent(e,index){
      if (Math.round(e.srcElement.scrollTop + 1 ) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.pageIndex++
        if(index == 2){
          this.getRecycleListData()
        }

      }
    },
    //清空回收站
    handelemptyrecycle(){
      emptyRecycle().then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          this.getRecycleListData()
          this.showDelete1 = false
        }else{
          this.$Message.error(`${res.msg}`)

        }
      })
    },
    //粉碎文件
    handelsmashfile(){
      if(this.deleteState) return  false
      let files_list = []
      for(let item in this.recycleList){
        if(this.recycleList[item].checked == true){
          files_list.push({
            id:this.recycleList[item].id,
            type:this.recycleList[item].type
          })
        }
      }
      let data = {
        files_list:files_list
      }

      this.deleteState = true

      smashRecycleFile(data).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          this.getRecycleListData()
          this.showCompleteDelete = false
          this.deleteState = false
        }else{
          this.$Message.error(`${res.msg}`)
        }
      }).catch(error=>{
        this.deleteState = false
      })
    },
  },
}
</script>

<style lang='less' scoped>
@import "@assets/css/profile/account/myFiles.less";
</style>